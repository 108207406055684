import Vue from "vue";
import Vuex, { ActionContext, StoreOptions } from "vuex";
import { RootState } from "./types";
import { admin } from "./modules/admin";
import { info } from "./modules/info";
import { teams } from "./modules/teams";
import { user } from "./modules/user";
import { virtual } from "./modules/virtual";
import { walkers } from "./modules/walkers";
import { shop } from "./modules/shop";
import {
  adminCollection,
  auth,
  store as fbStore,
} from "@/plugins/firebaseConfig";
import { ErrorType } from "@/types/enums";

Vue.use(Vuex);

auth.onAuthStateChanged((user: firebase.default.User | null) => {
  if (user) {
    store.commit("user/setCurrentUser", user);
    store.dispatch("fetchContent");
  } else {
    store.dispatch("initialFetch");
  }
});

const state = {
  version: "1.0.0",
  errorMessage: "",
  loading: true,
  loadingProcess: "",
};

const getters = {
  loaded(state: RootState): boolean {
    if (
      state.user &&
      state.user.profile &&
      state.user.profile.id &&
      !state.loading
    ) {
      return true;
    } else {
      return false;
    }
  },
};

const actions = {
  fetchContent(context: ActionContext<RootState, RootState>) {
    context.commit("startLoading", "index/fetchContent");
    return context
      .dispatch("user/fetchProfile")
      .then(() => {
        if (context.getters["user/isAdmin"]) {
          return Promise.all([
            context.dispatch("admin/fetchUsers"),
            context.dispatch("admin/fetchErrors"),
            context.dispatch("admin/fetchTrackers")
          ]);
        } else {
          return;
        }
      })
      .then(() => {
        return Promise.all([
          context.dispatch("walkers/fetchWalkers"),
          context.dispatch("teams/fetchTeams"),
          context.dispatch("info/fetchGroups"),
          context.dispatch("info/fetchConfig"),
          context.dispatch("info/fetchTrophies"),
          context.dispatch("info/fetchStats"),
          context.dispatch("virtual/fetchVirtualWalkers"),
          context.dispatch("info/fetchConfig"),
          context.dispatch("shop/fetchItems"),
        ]);
      })
      .then(() => {
        context.commit("finishLoading", "index/fetchContent");
      })
      .catch((err) => {
        context.commit("finishLoading", "index/fetchContent");
        context.commit("setErrorMessage", "Failed to fetch: " + err);
      });
  },
  async initialFetch(context: ActionContext<RootState, RootState>) {
    context.commit("startLoading", "index/fetchContent");
    try {
      await Promise.all([
        context.dispatch("info/fetchGroups"),
        context.dispatch("info/fetchConfig"),
      ]);
      context.commit("finishLoading", "index/fetchContent");
    } catch (err) {
      context.commit("finishLoading", "index/fetchContent");
      context.commit("setErrorMessage", "Failed to fetch: " + err);
    }
  },
};

const mutations = {
  setErrorMessage(state: RootState, message: string) {
    state.errorMessage = message;
    if (message === "") {
      return;
    }
    const user = state.user?.profile;
    adminCollection
      .doc("errors")
      .update({
        log: fbStore.FieldValue.arrayUnion({
          timestamp: fbStore.Timestamp.now(),
          userName: `${user?.firstName} ${user?.lastName}`,
          error: message,
          type: ErrorType.CLIENT,
        }),
      })
      .then(() => console.log("added error"))
      .catch((error) => console.log("adding error got error: ", error));
  },
  startLoading(state: RootState, process: string) {
    if (state.loadingProcess === "") {
      state.loadingProcess = process;
      state.loading = true;
    }
  },
  finishLoading(state: RootState, process: string) {
    if (state.loadingProcess === process) {
      state.loadingProcess = "";
      state.loading = false;
    }
  },
};

const rootStore: StoreOptions<RootState> = {
  state: state,
  modules: {
    admin,
    info,
    teams,
    user,
    virtual,
    walkers,
    shop,
  },
  getters: getters,
  actions: actions,
  mutations: mutations,
};

const store = new Vuex.Store<RootState>(rootStore);

export { state, getters, actions, mutations, store };
