
/* eslint-disable no-undef */
import { Team, UserProfile, Walker } from "@/types";
import { Route } from "@/types/enums";
import { getRouteColour } from "@/utils/colours";
import {
  getBronzeRoute,
  getCheckpointPosition,
  getDate,
  getFinish,
  getGoldRoute,
  getSilverRoute,
  getTimeToCheckpoint,
  getTimeToFinish,
  getTotalTime,
} from "@/utils/route";
import Vue from "vue";
import { Loader } from "@googlemaps/js-api-loader";
import moment from "moment";
import { parse } from "csv-parse";

interface TableWalker {
  walkerNumber: number;
  name: string;
  teamNumber?: number;
  trackerId: string;
  startTime: string;
  cp1: string;
  cp2: string;
  cp3: string;
  cp4: string;
  cp5: string;
  cp6: string;
  cp7: string;
  cp8: string;
  cp9: string;
  cp10: string;
  cp11: string;
  cp12: string;
  cp13: string;
  cp14: string;
  finishTime: string;
  status: string;
  timeToNextCP: string;
  timeToFinish: string;
}

interface TableTracker {
  trackerNumber: string;
  teamNumber?: number;
  returnTime?: string;
  status: string;
}

export default Vue.extend({
  data() {
    return {
      // eslint-disable-next-line no-undef
      map: {} as google.maps.Map,
      mapCenter: { lat: 51.80024, lng: -2.16587 },
      // eslint-disable-next-line no-undef
      markers: [] as google.maps.Marker[],
      walkerDialog: false,
      currentWalkers: [] as Walker[],
      tableHeaders: [
        {
          text: "Walker Number",
          align: "start",
          value: "walkerNumber",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Mobile Number",
          value: "mobileNumber",
        },
        {
          text: "Route",
          value: "route",
        },
        {
          text: "Tracker Number",
          value: "trackerId",
        },
        {
          text: "Last Seen",
          value: "lastTime",
        },
        {
          text: "Group Contact",
          value: "contact",
        },
      ],
      tab: null,
      mapInitialised: false,
      search: "",
      trackerSearch: "",
      walkerHeaders: [
        {
          text: "Walker Number",
          value: "walkerNumber",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Team Number",
          value: "teamNumber",
        },
        {
          text: "Tracker Number",
          value: "trackerId",
        },
        {
          text: "Start",
          value: "startTime",
        },
        {
          text: "CP1",
          value: "cp1",
        },
        {
          text: "CP2",
          value: "cp2",
        },
        {
          text: "CP3",
          value: "cp3",
        },
        {
          text: "CP4",
          value: "cp4",
        },
        {
          text: "CP5",
          value: "cp5",
        },
        {
          text: "CP6",
          value: "cp6",
        },
        {
          text: "CP7",
          value: "cp7",
        },
        {
          text: "CP8",
          value: "cp8",
        },
        {
          text: "CP9",
          value: "cp9",
        },
        {
          text: "CP10",
          value: "cp10",
        },
        {
          text: "CP11",
          value: "cp11",
        },
        {
          text: "CP12",
          value: "cp12",
        },
        {
          text: "CP13",
          value: "cp13",
        },
        {
          text: "CP14",
          value: "cp14",
        },
        {
          text: "Finish",
          value: "finishTime",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Est Time to Next CP",
          value: "timeToNextCP",
        },
        {
          text: "Est Time to Finish",
          value: "timeToFinish",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      trackerHeaders: [
        {
          text: "Tracker Number",
          value: "trackerNumber",
        },
        {
          text: "Label",
          value: "label",
          filterable: false,
        },
        {
          text: "Status",
          value: "status",
          filterable: false,
        },
        {
          text: "Return Time",
          value: "returnTime",
          filterable: false,
        },
        {
          text: "Actions",
          value: "actions",
          filterable: false,
        },
      ],
      checkpointHeaders: [
        {
          text: "CP Number",
          value: "number",
        },
        {
          text: "No. of Seen Walkers",
          value: "seenWalkers",
        },
        {
          text: "No. of Unseen Walkers",
          value: "unseenWalkers",
        },
        {
          text: "No. of walkers last seen at this checkpoint",
          value: "lastSeenWalkers",
        },
      ],
      currentTableWalker: {} as TableWalker,
      editDialog: false,
      trackerUploadDialog: false,
      trackerUploadFile: {} as File,
      trackerRecords: [] as { trackerNumber: string; teamNumber: string }[],
      addTrackerDialog: false,
      trackerNumberInput: "",
      trackerTypeInput: "",
      trackerLabelInput: "",
      trackerFilterDialog: false,
      trackerDeleteDialog: false,
      currentTrackerNumber: "",
      trackerStatusFilters: [
        "Unassigned",
        "Active",
        "Team Finished",
        "Returned",
      ],
      filteredTrackerStatuses: [0, 1, 2, 3],
    };
  },
  computed: {
    walkers(): Walker[] {
      return this.$store.state.walkers.walkers as Walker[];
    },
    walkingWalkers(): Walker[] {
      return this.walkers.filter(
        (w) => !w.finishTime && !w.retired && w.startTime
      );
    },
    teams(): Team[] {
      return this.$store.state.teams.teams as Team[];
    },
    tableWalkers(): TableWalker[] {
      return this.walkers.map((w) => {
        const team = this.teams.find(
          (t) =>
            t.walkers.includes(w.walkerNumber) ||
            t.additionalWalkers.includes(w.walkerNumber)
        );
        const lastCheckpoint = w.lastCheckpoint
          ? parseInt(w.lastCheckpoint)
          : 0;

        return {
          walkerNumber: w.walkerNumber,
          name: `${w.firstName} ${w.lastName}`,
          teamNumber: team?.teamNumber,
          trackerId: team?.trackerId || "",
          startTime: w.startTime,
          cp1: w.checkpointTimes?.find((v) => v.checkpoint === 1)?.time ?? "",
          cp2: w.checkpointTimes?.find((v) => v.checkpoint === 2)?.time ?? "",
          cp3: w.checkpointTimes?.find((v) => v.checkpoint === 3)?.time ?? "",
          cp4: w.checkpointTimes?.find((v) => v.checkpoint === 4)?.time ?? "",
          cp5: w.checkpointTimes?.find((v) => v.checkpoint === 5)?.time ?? "",
          cp6: w.checkpointTimes?.find((v) => v.checkpoint === 6)?.time ?? "",
          cp7: w.checkpointTimes?.find((v) => v.checkpoint === 7)?.time ?? "",
          cp8: w.checkpointTimes?.find((v) => v.checkpoint === 8)?.time ?? "",
          cp9: w.checkpointTimes?.find((v) => v.checkpoint === 9)?.time ?? "",
          cp10: w.checkpointTimes?.find((v) => v.checkpoint === 10)?.time ?? "",
          cp11: w.checkpointTimes?.find((v) => v.checkpoint === 11)?.time ?? "",
          cp12: w.checkpointTimes?.find((v) => v.checkpoint === 12)?.time ?? "",
          cp13: w.checkpointTimes?.find((v) => v.checkpoint === 13)?.time ?? "",
          cp14: w.checkpointTimes?.find((v) => v.checkpoint === 14)?.time ?? "",
          finishTime: w.finishTime,
          status: this.getStatus(w),
          timeToNextCP:
            this.getStatus(w) !== "Walking"
              ? ""
              : this.getTimeDifference(
                  new Date().toTimeString().substring(0, 5),
                  new Date(
                    getDate(w.lastTime ? w.lastTime : w.startTime).getTime() +
                      getTimeToCheckpoint(lastCheckpoint + 1)
                  )
                    .toTimeString()
                    .substring(0, 5)
                ),
          timeToFinish:
            this.getStatus(w) !== "Walking"
              ? ""
              : this.getTimeDifference(
                  new Date().toTimeString().substring(0, 5),
                  new Date(
                    getDate(w.lastTime ? w.lastTime : w.startTime).getTime() +
                      getTimeToFinish(lastCheckpoint, w.route)
                  )
                    .toTimeString()
                    .substring(0, 5)
                ),
        };
      });
    },
    tableTrackers(): TableTracker[] {
      const teams = this.teams;
      const teamTrackers = this.teams.map((team) => ({
        trackerNumber: team.trackerId,
        type: "team",
        label: `Team ${team.teamNumber}`,
      }));
      const otherTrackers = this.$store.state.admin.trackers.otherTrackers as {
        trackerNumber: string;
        type: string;
        label?: string;
      }[];
      const returnedTrackers = this.$store.state.admin.trackers
        .returnedTrackers as {
        trackerNumber: string;
        returnTime: firebase.default.firestore.Timestamp;
      }[];
      const statusArray = this.filteredTrackerStatuses.map(
        (v) => this.trackerStatusFilters[v]
      );

      return [...teamTrackers, ...otherTrackers]
        .sort((a, b) => {
          if (parseInt(a.trackerNumber) && parseInt(b.trackerNumber))
            return parseInt(a.trackerNumber) - parseInt(b.trackerNumber);
          return a.trackerNumber.localeCompare(b.trackerNumber);
        })
        .map((tracker) => {
          const team = teams.find((t) => t.trackerId === tracker.trackerNumber);
          const returnedTracker = returnedTrackers.find(
            (returnedTracker) =>
              returnedTracker.trackerNumber === tracker.trackerNumber
          );
          const status = returnedTracker
            ? "Returned"
            : team && team?.finishTime
            ? "Team Finished"
            : tracker.type === "team" && !team
            ? "Unassigned"
            : "Active";
          const label =
            tracker.type === "team"
              ? `Team ${team?.teamNumber}`
              : tracker.type === "spare"
              ? "Spare"
              : tracker.label;
          return {
            trackerNumber: tracker.trackerNumber,
            label,
            returnTime: returnedTracker?.returnTime.toDate().toLocaleString(),
            status,
            type: tracker.type,
          };
        })
        .filter(({ status }) => statusArray.includes(status));
    },
    tableCheckpoints() {
      let checkpoints: {}[] = [];
      for (let i = 1; i < 16; i++) {
        checkpoints.push({
          number: i,
          seenWalkers: this.walkers.filter(
            (w) =>
              (w.finishTime || parseInt(w.lastCheckpoint) >= i) &&
              i <= getFinish(w.route)
          ).length,
          unseenWalkers: this.walkingWalkers.filter(
            (w) =>
              (!w.lastCheckpoint || parseInt(w.lastCheckpoint) < i) &&
              i <= getFinish(w.route)
          ).length,
          lastSeenWalkers: this.walkingWalkers.filter(
            (w) => parseInt(w.lastCheckpoint) === i
          ).length,
        });
      }
      return checkpoints;
    },
    eventDates(): string[] {
      const date = (
        this.$store.state.info.dates.main
          .event as firebase.default.firestore.Timestamp
      ).toDate();
      const nextDate = moment(date).add(1, "day").toDate();
      return [
        date.toISOString().substr(0, 10),
        nextDate.toISOString().substr(0, 10),
      ];
    },
    users(): UserProfile[] {
      return this.$store.state.admin.users;
    },
  },
  methods: {
    initMap() {
      this.map = new window.google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          center: this.mapCenter,
          zoom: 12,
          maxZoom: 20,
          minZoom: 3,
          streetViewControl: false,
          mapTypeControl: true,
          fullscreenControl: false,
          zoomControl: true,
        }
      );

      new window.google.maps.Polyline({
        path: getBronzeRoute(),
        geodesic: true,
        strokeColor: getRouteColour(Route.BRONZE),
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }).setMap(this.map);

      new window.google.maps.Polyline({
        path: getSilverRoute(),
        geodesic: true,
        strokeColor: getRouteColour(Route.SILVER),
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }).setMap(this.map);

      new window.google.maps.Polyline({
        path: getGoldRoute(),
        geodesic: true,
        strokeColor: getRouteColour(Route.GOLD),
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }).setMap(this.map);

      let checkpointClosed =
        this.walkingWalkers.filter((w) => w.lastCheckpoint === "").length === 0;

      this.markers.push(
        this.placeCheckpointMarker(
          getCheckpointPosition(0),
          this.walkingWalkers.filter((w) => w.lastCheckpoint === ""),
          checkpointClosed
        )
      );

      for (let i = 1; i < 16; i++) {
        checkpointClosed =
          checkpointClosed &&
          this.walkingWalkers.filter((w) => w.lastCheckpoint === i.toString())
            .length === 0;
        this.markers.push(
          this.placeCheckpointMarker(
            getCheckpointPosition(i),
            this.walkingWalkers.filter(
              (w) => w.lastCheckpoint === i.toString()
            ),
            checkpointClosed
          )
        );
      }
    },
    placeCheckpointMarker(
      position: { lat: number; lng: number },
      walkers: Walker[],
      checkpointClosed: boolean
    ) {
      const marker = new window.google.maps.Marker({
        position: position,
        map: this.map,
        label: walkers.length.toString(),
        icon: {
          path: `M13.04,41.77c-0.11-1.29-0.35-3.2-0.99-5.42c-0.91-3.17-4.74-9.54-5.49-10.79c-3.64-6.1-5.46-9.21-5.45-12.07
        c0.03-4.57,2.77-7.72,3.21-8.22c0.52-0.58,4.12-4.47,9.8-4.17c4.73,0.24,7.67,3.23,8.45,4.07c0.47,0.51,3.22,3.61,3.31,8.11
        c0.06,3.01-1.89,6.26-5.78,12.77c-0.18,0.3-4.15,6.95-5.1,10.26c-0.64,2.24-0.89,4.17-1,5.48C13.68,41.78,13.36,41.78,13.04,41.77z
        `,
          fillColor: checkpointClosed ? "#4CAF50" : "#2196F3",
          fillOpacity: 1,
          strokeColor: "#ffffff",
          strokeWeight: 1,
          anchor: new (window as any).google.maps.Point(14, 43),
          labelOrigin: new (window as any).google.maps.Point(13.5, 15),
        },
      });

      marker.addListener("click", () => {
        this.currentWalkers = walkers;
        this.walkerDialog = true;
      });

      return marker;
    },
    getRouteColour(route: Route): string {
      return getRouteColour(route);
    },
    getTimeColour(time: string) {
      const currentTime = new Date().toTimeString().substr(0, 5);
      const elapsedTime = getTotalTime(currentTime, time);
      if (elapsedTime > "01:00") {
        return "error";
      } else if (elapsedTime > "00:45") {
        return "warning";
      } else {
        return "primary";
      }
    },
    getEstimatedTimeColour(time: string) {
      if (time <= "00:00") {
        return "error";
      } else if (time < "00:15") {
        return "warning";
      } else {
        return "primary";
      }
    },
    getContactName(id: string) {
      const contact = this.$store.getters["admin/getUser"](id) as UserProfile;
      return `${contact.firstName} ${contact.lastName}`;
    },
    getStatus(walker: Walker) {
      if (walker.finishTime) {
        return "Finished";
      } else if (this.getCheckpointTime(walker, getFinish(walker.route))) {
        return "Finished, awaiting pickup";
      } else if (walker.retired) {
        if (walker.awaitingPickup) {
          return "Retired, awaiting pickup";
        } else {
          return "Retired";
        }
      } else if (walker.startTime) {
        return "Walking";
      } else {
        return "Not Started";
      }
    },
    getCheckpointTime(walker: Walker, checkpoint: number) {
      return walker.checkpointTimes?.find((v) => v.checkpoint === checkpoint)
        ?.time;
    },
    getTimeDifference(expectedTime: string, currentTime: string) {
      const expectedTimeDate =
        expectedTime > "12:00"
          ? new Date(`${this.eventDates[0]}T${expectedTime}:00`)
          : new Date(`${this.eventDates[1]}T${expectedTime}:00`);
      const currentTimeDate =
        currentTime > "12:00"
          ? new Date(`${this.eventDates[0]}T${currentTime}:00`)
          : new Date(`${this.eventDates[1]}T${currentTime}:00`);
      const diff = currentTimeDate.getTime() - expectedTimeDate.getTime();
      return diff < 0 ? "00:00" : new Date(diff).toISOString().slice(11, 16);
    },
    getStatusColor(status: string): string {
      switch (status) {
        case "Finished":
          return "success";
        case "Walking":
          return "primary";
        case "Not Started":
          return "warning";
        case "Retired":
        default:
          return "error";
      }
    },
    getTrackerStatusColor(status: string): string {
      switch (status) {
        case "Returned":
          return "success";
        case "Active":
          return "primary";
        case "Team Finished":
          return "warning";
        case "Unassigned":
        default:
          return "error";
      }
    },
    getRoute(walkerNumber: number) {
      if (walkerNumber < this.$store.state.info.walkerNumbers.start.Silver) {
        return Route.BRONZE;
      } else if (
        walkerNumber < this.$store.state.info.walkerNumbers.start.Gold
      ) {
        return Route.SILVER;
      } else {
        return Route.GOLD;
      }
    },
    downloadWalkers() {
      const teams = this.$store.state.teams.teams as Team[];

      const walkerList = [];
      const walkerNumbers: number[] = [];

      teams.forEach((t) => {
        t.walkers.concat(t.additionalWalkers).forEach((walkerNumber) => {
          const w = this.$store.getters["walkers/getWalker"](
            walkerNumber
          ) as Walker;
          walkerList.push({
            walkerNumber: walkerNumber,
            name: `${w.firstName} ${w.lastName}`,
            teamNumber: t.teamNumber,
            trackerId: t.trackerId,
            mobileNumber: w.mobileNumber,
            status: this.getStatus(w),
            startTime: w.startTime,
            cp1: w.checkpointTimes?.find((v) => v.checkpoint === 1)?.time,
            cp2: w.checkpointTimes?.find((v) => v.checkpoint === 2)?.time,
            cp3: w.checkpointTimes?.find((v) => v.checkpoint === 3)?.time,
            cp4: w.checkpointTimes?.find((v) => v.checkpoint === 4)?.time,
            cp5: w.checkpointTimes?.find((v) => v.checkpoint === 5)?.time,
            cp6: w.checkpointTimes?.find((v) => v.checkpoint === 6)?.time,
            cp7: w.checkpointTimes?.find((v) => v.checkpoint === 7)?.time,
            cp8: w.checkpointTimes?.find((v) => v.checkpoint === 8)?.time,
            cp9: w.checkpointTimes?.find((v) => v.checkpoint === 9)?.time,
            cp10: w.checkpointTimes?.find((v) => v.checkpoint === 10)?.time,
            cp11: w.checkpointTimes?.find((v) => v.checkpoint === 11)?.time,
            cp12: w.checkpointTimes?.find((v) => v.checkpoint === 12)?.time,
            cp13: w.checkpointTimes?.find((v) => v.checkpoint === 13)?.time,
            cp14: w.checkpointTimes?.find((v) => v.checkpoint === 14)?.time,
            finishTime: w.finishTime,
          });
          walkerNumbers.push(walkerNumber);
        });
      });

      for (
        let i = 1;
        i <
        this.$store.state.info.walkerNumbers.start.Gold +
          this.$store.state.info.walkerNumbers.limit.Gold;
        i++
      ) {
        if (!walkerNumbers.includes(i)) {
          walkerList.push({
            walkerNumber: i,
            name: "",
            teamNumber: "",
            trackerId: "",
            mobileNumber: "",
            status: "Not Started",
            startTime: "",
            cp1: "",
            cp2: "",
            cp3: "",
            cp4: "",
            cp5: "",
            cp6: "",
            cp7: "",
            cp8: "",
            cp9: "",
            cp10: "",
            cp11: "",
            cp12: "",
            cp13: "",
            cp14: "",
            finishTime: "",
          });
        }
      }

      const header =
        "Walker Number,Name,Team Number,Tracker Number,Mobile Number,Status,Start Time,CP1,CP2,CP3,CP4,CP5,CP6,CP7,CP8,CP9,CP10,CP11,CP12,CP13,CP14,Finish Time";
      const values = walkerList
        .map((o) => {
          return [
            `"${o.walkerNumber}"`,
            `"${o.name}"`,
            `"${o.teamNumber}"`,
            `"${o.trackerId || ""}"`,
            `"${o.mobileNumber}"`,
            `"${o.status}"`,
            `"${o.startTime}"`,
            `"${o.cp1 ? o.cp1 : ""}"`,
            `"${o.cp2 ? o.cp2 : ""}"`,
            `"${o.cp3 ? o.cp3 : ""}"`,
            `"${o.cp4 ? o.cp4 : ""}"`,
            `"${o.cp5 ? o.cp5 : ""}"`,
            `"${o.cp6 ? o.cp6 : ""}"`,
            `"${o.cp7 ? o.cp7 : ""}"`,
            `"${o.cp8 ? o.cp8 : ""}"`,
            `"${o.cp9 ? o.cp9 : ""}"`,
            `"${o.cp10 ? o.cp10 : ""}"`,
            `"${o.cp11 ? o.cp11 : ""}"`,
            `"${o.cp12 ? o.cp12 : ""}"`,
            `"${o.cp13 ? o.cp13 : ""}"`,
            `"${o.cp14 ? o.cp14 : ""}"`,
            `"${o.finishTime}"`,
          ];
        })
        .join("\n");

      const encodedUri = encodeURI(
        "data:text/csv;charset=utf-8," + header + "\n" + values
      );
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "walkersTracking.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadTeamTrackerInformation() {
      const header = "bib,name,club,category";
      const values = this.teams
        .sort((a, b) => parseInt(a.trackerId) - parseInt(b.trackerId))
        .map((o) => {
          const w = this.$store.getters["walkers/getWalker"](
            o.walkers[0]
          ) as Walker;
          return [
            `"${o.trackerId}"`,
            `"[${[...o.walkers, ...o.additionalWalkers].join(", ")}]"`,
            `"${w.group}"`,
            `"${o.route}"`,
          ].join(",");
        })
        .join("\n");

      const encodedUri = encodeURI(
        "data:text/csv;charset=utf-8," + header + "\n" + values
      );
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "teamTrackerInformation.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openEditDialog(tableWalker: any) {
      this.currentTableWalker = tableWalker;
      this.editDialog = true;
    },
    saveWalkerTimes() {
      const walker = this.walkers.find(
        (w) => w.walkerNumber === this.currentTableWalker.walkerNumber
      );
      if (!walker) {
        return;
      }
      const newTimes = [
        {
          checkpoint: 1,
          time: this.currentTableWalker.cp1,
        },
        {
          checkpoint: 2,
          time: this.currentTableWalker.cp2,
        },
        {
          checkpoint: 3,
          time: this.currentTableWalker.cp3,
        },
        {
          checkpoint: 4,
          time: this.currentTableWalker.cp4,
        },
        {
          checkpoint: 5,
          time: this.currentTableWalker.cp5,
        },
        {
          checkpoint: 6,
          time: this.currentTableWalker.cp6,
        },
        {
          checkpoint: 7,
          time: this.currentTableWalker.cp7,
        },
        {
          checkpoint: 8,
          time: this.currentTableWalker.cp8,
        },
        {
          checkpoint: 9,
          time: this.currentTableWalker.cp9,
        },
        {
          checkpoint: 10,
          time: this.currentTableWalker.cp10,
        },
        {
          checkpoint: 11,
          time: this.currentTableWalker.cp11,
        },
        {
          checkpoint: 12,
          time: this.currentTableWalker.cp12,
        },
        {
          checkpoint: 13,
          time: this.currentTableWalker.cp13,
        },
        {
          checkpoint: 14,
          time: this.currentTableWalker.cp14,
        },
      ].filter((v) => {
        const checkpointTime = walker.checkpointTimes?.find(
          (u) => u.checkpoint === v.checkpoint
        );
        return v.time !== checkpointTime?.time;
      });
      console.log(newTimes);
      walker.checkpointTimes = walker.checkpointTimes
        .filter((v) => !newTimes.find((u) => u.checkpoint === v.checkpoint))
        .concat(newTimes)
        .filter((v) => v.time);
      console.log(walker.checkpointTimes);
      this.$store
        .dispatch("walkers/updateWalker", walker)
        .then(() => {
          this.editDialog = false;
          this.currentTableWalker = {} as TableWalker;
        })
        .catch((err) => {
          throw err;
        });
    },
    addTrackingInformation() {
      this.$store.commit("startLoading", "tracking/addTrackingInformation");
      Promise.all(
        this.trackerRecords.map(({ trackerNumber, teamNumber }) => {
          const team = this.teams.find(
            (t) => t.teamNumber === parseInt(teamNumber)
          );
          if (team) {
            team.trackerId = trackerNumber;
            return this.$store.dispatch("teams/updateTeam", team);
          } else {
            return Promise.resolve();
          }
        })
      ).then(() => {
        this.$store.commit("finishLoading", "tracking/addTrackingInformation");
      });
    },
    returnTracker(trackerNumber: string) {
      this.$store.dispatch("admin/returnTracker", trackerNumber);
    },
    unreturnTracker(trackerNumber: string) {
      this.$store.dispatch("admin/unreturnTracker", trackerNumber);
    },
    addTracker() {
      const newTracker = {
        trackerNumber: this.trackerNumberInput,
        type: this.trackerTypeInput.toLowerCase(),
        label: this.trackerLabelInput,
      };
      this.$store.dispatch("admin/addTracker", newTracker);
      this.trackerNumberInput = "";
      this.trackerTypeInput = "";
      this.trackerLabelInput = "";
      this.addTrackerDialog = false;
    },
    deleteTracker() {
      this.$store.dispatch("admin/deleteTracker", this.currentTrackerNumber);
      this.trackerDeleteDialog = false;
      this.currentTrackerNumber = "";
    },
    openTrackerDeleteDialog(trackerNumber: string) {
      console.log({ trackerNumber });
      this.currentTrackerNumber = trackerNumber;
      this.trackerDeleteDialog = true;
    },
    resetFilters() {
      this.filteredTrackerStatuses = [0, 1, 2, 3];
    },
    isReturned(tracker: TableTracker) {
      console.log(tracker.status === "Returned");
      return tracker.status === "Returned";
    },
    trackerTableFilter(value: any, search: string | null, item: any) {
      console.log({ value, search, item });
      return value === search;
    },
  },
  watch: {
    tab() {
      if (this.tab === "map" && this.mapInitialised === false) {
        const loader = new Loader({
          apiKey: "AIzaSyD5CPeoVg446XFDrV47EHy7BfOsu3eo2LU",
        });

        this.$store.commit("startLoading", "tracking/loadMap");
        loader
          .load()
          .then(() => {
            this.initMap();
            this.mapInitialised = true;
            this.$store.commit("finishLoading", "tracking/loadMap");
          })
          .catch((err) => {
            throw err;
          });
      }
    },
    trackerUploadFile() {
      this.$store.commit("startLoading", "tracking/trackerUploadFileChanged");
      if (this.trackerUploadFile.type !== "text/csv") {
        console.log(this.trackerUploadFile.type);
        this.$store.commit(
          "finishLoading",
          "tracking/trackerUploadFileChanged"
        );
        this.$store.commit(
          "setErrorMessage",
          "Bulk tracker uploads must be in a CSV file format"
        );
      } else {
        this.trackerUploadFile.text().then((fileContents) => {
          parse(
            fileContents,
            {
              columns: ["trackerNumber", "walkerNumber"],
              fromLine: 2,
            },
            (err, records) => {
              this.$store.commit(
                "finishLoading",
                "tracking/trackerUploadFileChanged"
              );
              if (err) {
                this.$store.commit(
                  "setErrorMessage",
                  "The tracker information could not be parsed correctly: " +
                    err
                );
              } else {
                this.trackerRecords = records;
              }
            }
          );
        });
      }
    },
  },
  mounted() {},
});
