import {
  adminCollection,
  store,
  usersCollection,
} from "@/plugins/firebaseConfig";
import {
  ErrorLog,
  LeaderProfile,
  ParentProfile,
  ShopItem,
  StaffProfile,
  UserProfile,
} from "@/types";
import { Role } from "@/types/enums";
import { calculateDeliveryCost, calculateBasketCost } from "@/utils/costs";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AdminState, RootState } from "../types";

const namespaced: boolean = true;

export const state: AdminState = {
  users: [],
  errors: [],
  trackers: {
    otherTrackers: [],
    returnedTrackers: []
  }
};

export const getters: GetterTree<AdminState, RootState> = {
  staff: (state): StaffProfile[] => {
    return state.users.filter((v) => {
      return (
        v.role === Role.STAFF ||
        v.role === Role.ENHANCED_STAFF ||
        v.role === Role.ADMIN
      );
    }) as StaffProfile[];
  },
  totalOutstandingBalance: (state, getters): number => {
    return state.users
      .map((user) => {
        return getters.totalOutstandingBalanceForUser(user.id);
      })
      .reduce((a, b) => {
        return a + b;
      }, 0);
  },
  totalPaid: (state): number => {
    const totals = state.users.map((user) => {
      return user.totalPaid;
    });
    const total = totals.reduce((a, b) => {
      return a + b;
    }, 0);
    return total;
  },
  totalOutstandingBalanceForUser:
    (state, getters) =>
    (id: string): number => {
      const user = getters.getUser(id);
      return getters.totalCostForUser(id) - user.totalPaid;
    },
  totalCostForUser:
    (state, getters, rootState, rootGetters) =>
    (id: string): number => {
      const user = getters.getUser(id);
      const items = rootState.shop?.shopItems ?? [];
      return (
        calculateBasketCost(user.basket, items) +
        calculateDeliveryCost(user.basket, user.deliveryMethod, items) +
        rootGetters["walkers/totalCostForUser"](id)
      );
    },
  getUser:
    (state) =>
    (id: string): UserProfile => {
      const user = state.users.find((u) => {
        return u.id === id;
      });
      if (user) {
        return user;
      } else {
        throw new Error(`Cannot find user with id ${id}`);
      }
    },
  getQuantitySold:
    (state, getters, rootState, rootGetters) =>
    (id: string): number => {
      const item: ShopItem = rootGetters["shop/getItem"](id);
      return state.users
        .flatMap((user) =>
          user.basket
            .filter((basketItem) => basketItem.id === item.id)
            .map((basketItem) => basketItem.quantity)
        )
        .reduce((a, b) => a + b, 0);
    },
};

export const actions: ActionTree<AdminState, RootState> = {
  fetchUsers(context) {
    context.commit("startLoading", "admin/fetchUsers", { root: true });
    return usersCollection
      .get()
      .then((query) => {
        const docs = query.docs;
        const users = docs.map((x) => {
          const data = x.data();
          data.id = x.id;
          return data;
        });
        context.commit("setUsers", users);
        context.commit("finishLoading", "admin/fetchUsers", { root: true });
        return;
      })
      .catch((err) => {
        context.commit("finishLoading", "admin/fetchUsers", { root: true });
        context.commit("setErrorMessage", "Cannot fetch users: " + err, {
          root: true,
        });
        return;
      });
  },
  fetchErrors(context) {
    context.commit("startLoading", "admin/fetchErrors", { root: true });
    return adminCollection
      .doc("errors")
      .get()
      .then((query) => {
        context.commit("setErrors", query.data()?.log ?? []);
        context.commit("finishLoading", "admin/fetchErrors", { root: true });
        return;
      })
      .catch((err) => {
        context.commit("finishLoading", "admin/fetchErrors", { root: true });
        context.commit("setErrorMessage", "Cannot fetch errors: " + err, {
          root: true,
        });
        return;
      });
  },
  fetchTrackers(context) {
    context.commit("startLoading", "admin/fetchTrackers", { root: true });
    return adminCollection
      .doc("trackers")
      .get()
      .then((doc) => {
        context.commit("setTrackers", doc.data());
        context.commit("finishLoading", "admin/fetchTrackers", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "admin/fetchTrackers", { root: true });
        context.commit("setErrorMessage", `Cannot fetch trackers: ${err}`, {
          root: true,
        });
      });
  },
  addPayment(
    context,
    { user, payment }: { user: UserProfile; payment: number }
  ) {
    context.commit("startLoading", "admin/addPayment", { root: true });
    return usersCollection
      .doc(user.id)
      .update({
        totalPaid: store.FieldValue.increment(payment),
        paymentSent: false,
      })
      .then(() => {
        user.totalPaid += payment;
        user.paymentSent = false;
        context.commit("setUser", user);
        context.commit("finishLoading", "admin/addPayment", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "admin/addPayment", { root: true });
        context.commit(
          "setErrorMessage",
          "Could not add payment to the database: " + err,
          {
            root: true,
          }
        );
        return;
      });
  },
  deleteUser(context, user: UserProfile) {
    context.commit("startLoading", "admin/deleteUser", { root: true });
    return usersCollection
      .doc(user.id)
      .delete()
      .then(() => {
        const userArray = context.state.users.filter(
          (c: UserProfile) => c.id !== user.id
        );
        return context.commit("setUsers", userArray);
      })
      .then(() => {
        return context.dispatch("virtual/deleteWalkersByUserID", user.id, {
          root: true,
        });
      })
      .then(() => {
        return context.dispatch("walkers/deleteWalkersByUserID", user.id, {
          root: true,
        });
      })
      .then(() => {
        context.commit("finishLoading", "admin/deleteUser", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "admin/deleteUser", { root: true });
        context.commit("setErrorMessage", `Error deleting user: ${err}`, {
          root: true,
        });
      });
  },
  updateUser(context, profile: UserProfile) {
    context.commit("startLoading", "admin/updateUser", { root: true });
    return usersCollection
      .doc(profile.id)
      .update(profile)
      .then(() => {
        return context.dispatch("info/addGroup", profile.group, { root: true });
      })
      .then(() => {
        context.commit("setUser", profile);
        context.commit("finishLoading", "admin/updateUser", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "admin/updateUser", { root: true });
        context.commit("setErrorMessage", `Failed to update user: ${err}`, {
          root: true,
        });
      });
  },
  addTracker(context, tracker) {
    context.commit("startLoading", "admin/addTracker", { root: true });
    return adminCollection
      .doc("trackers")
      .update({
        otherTrackers: store.FieldValue.arrayUnion(tracker)
      })
      .then(() => {
        context.commit("appendTracker", tracker);
        context.commit("finishLoading", "admin/addTracker", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "admin/addTracker", { root: true });
        context.commit("setErrorMessage", `Cannot add tracker: ${err}`, {
          root: true,
        });
      });
  },
  deleteTracker(context, trackerNumber) {
    context.commit("startLoading", "admin/deleteTracker", { root: true });
    const tracker = context.state.trackers.otherTrackers.find(({trackerNumber: otherTrackerNumber}) => trackerNumber === otherTrackerNumber)
    console.log({trackerNumber, tracker})
    return adminCollection
      .doc("trackers")
      .update({
        otherTrackers: store.FieldValue.arrayRemove(tracker)
      })
      .then(() => {
        context.commit("removeTracker", tracker);
        context.commit("finishLoading", "admin/deleteTracker", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "admin/deleteTracker", { root: true });
        context.commit("setErrorMessage", `Cannot delete tracker: ${err}`, {
          root: true,
        });
      });
  },
  returnTracker(context, trackerNumber) {
    context.commit("startLoading", "admin/returnTracker", { root: true });
    const returnedTracker = {
      trackerNumber,
      returnTime: store.Timestamp.now()
    }
    return adminCollection
      .doc("trackers")
      .update({
        returnedTrackers: store.FieldValue.arrayUnion(returnedTracker)
      })
      .then(() => {
        context.commit("appendReturnedTracker", returnedTracker);
        context.commit("finishLoading", "admin/returnTracker", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "admin/returnTracker", { root: true });
        context.commit("setErrorMessage", `Cannot return tracker: ${err}`, {
          root: true,
        });
      });
  },
  unreturnTracker(context, trackerNumber) {
    context.commit("startLoading", "admin/unreturnTracker", { root: true });
    const returnedTracker = context.state.trackers.returnedTrackers.find(({trackerNumber: returnedTrackerNumber}) => trackerNumber === returnedTrackerNumber)
    return adminCollection
      .doc("trackers")
      .update({
        returnedTrackers: store.FieldValue.arrayRemove(returnedTracker)
      })
      .then(() => {
        context.commit("removeReturnedTracker", returnedTracker);
        context.commit("finishLoading", "admin/unreturnTracker", { root: true });
      })
      .catch((err) => {
        context.commit("finishLoading", "admin/unreturnTracker", { root: true });
        context.commit("setErrorMessage", `Cannot unreturn tracker: ${err}`, {
          root: true,
        });
      });
  },
  clearData: {
    root: true,
    handler(context) {
      context.commit("setUsers", []);
      context.commit("setErrors", []);
    },
  },
};

export const mutations: MutationTree<AdminState> = {
  setUsers(state, val: (ParentProfile | LeaderProfile | StaffProfile)[]) {
    state.users = val.sort((a: UserProfile, b: UserProfile) =>
      a.firstName.localeCompare(b.firstName)
    );
  },
  setUser(state, val: ParentProfile | LeaderProfile | StaffProfile) {
    const i = state.users.findIndex((user) => user.id === val.id);
    state.users[i] = val;
  },
  setErrors(state, val: ErrorLog[]) {
    state.errors = val.sort((a: ErrorLog, b: ErrorLog) => {
      return b.timestamp.nanoseconds - a.timestamp.nanoseconds;
    });
  },
  setTrackers(state, val: { otherTrackers: { trackerNumber: string; type: string; label?: string}[]; returnedTrackers: {trackerNumber: string; returnTime: firebase.default.firestore.Timestamp}[] }) {
    state.trackers = val;
  },
  appendReturnedTracker(state, val: {trackerNumber: string; returnTime: firebase.default.firestore.Timestamp}) {
    state.trackers.returnedTrackers.push(val);
  },
  removeReturnedTracker(state, val: {trackerNumber: string; returnTime: firebase.default.firestore.Timestamp}) {
    state.trackers.returnedTrackers = state.trackers.returnedTrackers.filter(({trackerNumber}) => val.trackerNumber !== trackerNumber);
  },
  appendTracker(state, val: {trackerNumber: string; type: string; label?: string}) {
    state.trackers.otherTrackers.push(val);
  },
  removeTracker(state, val: {trackerNumber: string; type: string; label?: string}) {
    state.trackers.otherTrackers = state.trackers.otherTrackers.filter(({trackerNumber}) => val.trackerNumber !== trackerNumber);
  },
};

export const admin: Module<AdminState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
