
/* eslint-disable no-undef */
import { UserProfile, VForm, Walker } from "@/types";
import Vue from "vue";
import UserForm from "./UserForm.vue";
import { createInvoice } from "../utils/invoice";
import { createLabels } from "../utils/labels";
import { getPaymentStatus, getReference } from "@/utils/users";
import { getPaymentStatusColour } from "@/utils/colours";
import { calculateDeliveryCost, calculateBasketCost } from "@/utils/costs";
import { functions } from "@/plugins/firebaseConfig";
import { DeliveryMethod } from "@/types/enums";

enum PaymentStatus {
  NONE = "",
  NOT_PAID = "Not Paid",
  PAYMENT_SENT = "Payment Sent",
  PARTIALLY_PAID = "Partially Paid",
  PAID = "Paid",
}

interface TableContact {
  name: string;
  email: string;
  phoneNumber: string;
  group: string;
  paymentStatus: PaymentStatus;
  reference: string;
  balance: string;
  id: string;
  totalCost: string;
  totalPaid: string;
}

export default Vue.extend({
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone Number",
          value: "phoneNumber",
        },
        {
          text: "Group",
          value: "group",
        },
        {
          text: "Payment Status",
          value: "paymentStatus",
        },
        {
          text: "Payment Reference",
          value: "reference",
        },
        {
          text: "Total Cost",
          value: "totalCost",
        },
        {
          text: "Total Paid",
          value: "totalPaid",
        },
        {
          text: "Outstanding Balance",
          value: "balance",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      currentContact: {} as UserProfile,
      paymentForm: false,
      paymentAmount: "0",
      editForm: false,
      deleteDialog: false,
      search: "",
      paymentEmailDialog: false,
      paymentEmailsConfirmation: false,
      userDownloadDialog: false,
      labelDownloadDialog: false,
      includeUsersWithWalkers: false,
      includeUsersWithPostalOrders: false,
    };
  },
  computed: {
    paymentFormRef(): VForm {
      return this.$refs.paymentForm as VForm;
    },
    contacts(): UserProfile[] {
      return this.$store.state.admin.users;
    },
    tableContacts(): TableContact[] {
      return this.contacts.map((c) => {
        return {
          name: c.firstName + " " + c.lastName,
          email: c.email,
          phoneNumber: c.phoneNumber,
          group: c.group,
          paymentStatus: this.getStatus(c),
          reference: this.getReference(c),
          balance: `£${this.$store.getters[
            "admin/totalOutstandingBalanceForUser"
          ](c.id).toFixed(2)}`,
          id: c.id,
          totalCost: `£${this.$store.getters["admin/totalCostForUser"](
            c.id
          ).toFixed(2)}`,
          totalPaid: `£${c.totalPaid.toFixed(2)}`,
        };
      });
    },
    eventDate() {
      return (
        this.$store.state.info.dates.main
          .event as firebase.default.firestore.Timestamp
      ).toDate();
    },
  },
  methods: {
    openPaymentForm(item: UserProfile) {
      if (item && item.id) {
        this.currentContact = this.getContact(item.id);
        this.paymentForm = true;
      }
    },
    confirmPayment() {
      if (
        this.currentContact &&
        this.currentContact.id &&
        this.paymentFormRef.validate()
      ) {
        const payload = {
          user: this.currentContact,
          payment: parseFloat(this.paymentAmount),
        };
        this.$store
          .dispatch("admin/addPayment", payload)
          .then(() => {
            this.paymentForm = false;
          })
          .catch((err) => {
            throw err;
          });
      }
    },
    openEditForm(item: UserProfile) {
      if (item && item.id) {
        this.currentContact = this.getContact(item.id);
        this.editForm = true;
      }
    },
    openDeleteDialog(item: UserProfile) {
      if (item && item.id) {
        this.currentContact = this.getContact(item.id);
        this.deleteDialog = true;
      }
    },
    confirmDelete() {
      if (this.currentContact && this.currentContact.id) {
        this.$store
          .dispatch("admin/deleteUser", this.currentContact)
          .then(() => {
            this.currentContact = {} as UserProfile;
            this.deleteDialog = false;
          })
          .catch((err) => {
            console.error("Failed to delete user: ", err);
          });
      }
    },
    downloadMarathonContacts() {
      this.downloadUsers(
        this.contacts.filter(
          (user) =>
            this.$store.getters["walkers/getWalkers"](user.id).length > 0
        ),
        "groupContacts.csv"
      );
    },
    downloadUsers(users?: UserProfile[], filename = "users.csv") {
      const csvString = this.makeCsv(users || this.contacts);
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.userDownloadDialog = false;
    },
    makeCsv(data: UserProfile[]): string {
      const header =
        "First Name,Last Name,Phone Number,Email,Group,Address Line 1,Address Line 2,Town,County,Postcode,Total Cost,Delivery Cost,Total Paid,Delivery Method";
      const shopItems = this.$store.state.shop.shopItems;
      const values = data
        .map((o) => {
          return [
            `"${o.firstName}"`,
            `"${o.lastName}"`,
            `="${o.phoneNumber}"`,
            `"${o.email}"`,
            `"${o.group}"`,
            o.address ? `"${o.address.line1}"` : "",
            o.address ? `"${o.address.line2}"` : "",
            o.address ? `"${o.address.town}"` : "",
            o.address ? `"${o.address.county}"` : "",
            o.address ? `"${o.address.postcode}"` : "",
            `"${
              calculateBasketCost(o.basket, shopItems) +
              this.$store.getters["walkers/totalCostForUser"](o.id)
            }"`,
            `"${calculateDeliveryCost(o.basket, o.deliveryMethod, shopItems)}"`,
            `"${o.totalPaid}"`,
            `"${o.deliveryMethod ? o.deliveryMethod : ""}"`,
          ].join(",");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    getReference(user: UserProfile): string {
      return getReference(user.id);
    },
    getStatus(user: UserProfile): PaymentStatus {
      return getPaymentStatus(
        user,
        this.$store.getters["admin/totalCostForUser"](user.id)
      );
    },
    getStatusColour(status: PaymentStatus): string {
      return getPaymentStatusColour(status);
    },
    getContact(id: string): UserProfile {
      return this.$store.getters["admin/getUser"](id);
    },
    downloadInvoice(user: TableContact) {
      const contact = this.getContact(user.id);
      const walkers = this.$store.getters["walkers/getWalkers"](user.id);
      const shopItems = this.$store.state.shop.shopItems;
      if (contact) {
        createInvoice(
          contact,
          walkers,
          this.$store.getters["admin/totalCostForUser"](user.id),
          shopItems
        );
      }
    },
    generateLabels() {
      const sortedUsers = [...this.contacts]
        .sort((a, b) => {
          const aMatch = a.group.match(/(\d*)/);
          const bMatch = b.group.match(/(\d*)/);
          if (aMatch && aMatch[0] && bMatch && bMatch[0]) {
            const diff = parseInt(aMatch[0]) - parseInt(bMatch[0]);
            if (diff !== 0) return diff;
          }
          return a.group.localeCompare(b.group);
        })
        .filter(
          (u) =>
            (this.includeUsersWithWalkers &&
              (this.$store.getters["walkers/getWalkers"](u.id) as Walker[])
                .length > 0) ||
            (this.includeUsersWithPostalOrders &&
              (this.$store.getters["admin/totalCostForUser"](u.id) as number) >
                0 &&
              u.deliveryMethod === DeliveryMethod.POST)
        );
      createLabels(sortedUsers);
    },
    sendPaymentEmail(user: TableContact) {
      if (
        this.$store.getters["admin/totalOutstandingBalanceForUser"](user.id) > 0
      ) {
        const sendEmail = functions.httpsCallable("sendPaymentReminderEmail");
        sendEmail({
          user: this.getContact(user.id),
          cost: this.$store.getters["admin/totalOutstandingBalanceForUser"](
            user.id
          ),
          eventDate: this.eventDate.getTime(),
        })
          .then(() => {
            this.paymentEmailsConfirmation = true;
          })
          .catch((err) => {
            this.$store.commit(
              "setErrorMessage",
              `Failed to send payment reminder email: ${err}`
            );
          });
      }
    },
    sendAllPaymentEmails() {
      this.$store.commit(
        "startLoading",
        "functions/sendMultiplePaymentReminderEmails"
      );
      const users = this.contacts
        .filter(
          (c) =>
            this.$store.getters["admin/totalOutstandingBalanceForUser"](c.id) >
            0
        )
        .map((c) => {
          return {
            profile: c,
            cost: this.$store.getters["admin/totalOutstandingBalanceForUser"](
              c.id
            ),
          };
        });
      const sendEmails = functions.httpsCallable(
        "sendMultiplePaymentReminderEmails"
      );
      sendEmails({
        users: users,
        eventDate: this.eventDate.getTime(),
      })
        .then(() => {
          this.$store.commit(
            "finishLoading",
            "functions/sendMultiplePaymentReminderEmails"
          );
          this.paymentEmailDialog = false;
          this.paymentEmailsConfirmation = true;
        })
        .catch((err) => {
          this.$store.commit(
            "finishLoading",
            "functions/sendMultiplePaymentReminderEmails"
          );
          this.$store.commit(
            "setErrorMessage",
            `Failed to send payment reminder email: ${err}`
          );
        });
    },
    resendSignUpEmail(user: TableContact) {
      const sendEmail = functions.httpsCallable("sendSignUpEmail");
      sendEmail(this.getContact(user.id))
        .then(() => {
          this.paymentEmailsConfirmation = true;
        })
        .catch((err) => {
          this.$store.commit(
            "setErrorMessage",
            `Failed to re-send sign up email: ${err}`
          );
        });
    },
  },
  components: {
    UserForm,
  },
});
