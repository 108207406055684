const gloucesterGroups = [
  "1st Innsworth",
  "22nd Gloucester (St Barnabas)",
  "48th Gloucester (Quedgeley) Scouts",
  "38th Longlevens Scout Group",
  "1st Upton St Leonards",
  "5th Gloucester Guides",
  "11th Gloucester (Hucclecote)",
  "Falcon Explorers",
  "Unmentionables ESU",
  "21st Gloucester Guides",
  "Churchdown / Phoenix Explorers",
  "Double 2 ESU",
  "STAAB",
  "Javelin Explorer Scout Unit",
  "21st Gloucester Guides/1st Longlevens Rangers",
  "5th Gloucester Guides",
  "45th Gloucester (Abbeydale) Scouts",
  "46th Gloucester",
  "38th Longlevens Gloucester",
  "Nutty Badgers ESU",
  "51st Gloucester",
  "51st Gloucester Cubs",
  "48th Gloucester (Quedgeley) Scouts",
  "48th Gloucester (Quedgeley)",
  "4th Churchdown (St. John's) Brownies",
  "1st Hardwicke",
  "41st Gloucester cubs",
  "Hardwicke Scouts",
  "19th Gloucester Brownies",
  "Churchdown",
  "41st Gloucester beavers",
  "Brockworth",
  "1st Robinswood District Rainbows",
  "Gladiators ESU",
  "38th Gloucester (Longlevens)",
  "7th Gloucester Rangers",
  "Follow that Goat",
];

const gloucestershireGroups = [
  ...gloucesterGroups,
  "1st Royal Forest Scout Group",
  "1st Cheltenham (Highbury)",
  "1st Dursley",
  "1st Wotton under Edge",
  "1st Moreton-in-Marsh",
  "1st Newent Scout Group",
  "1st Birdlip & Witcombe Guides and Rangers",
  "41st Cheltenham",
  "1st Staunton and Corse",
  "15th Cheltenham (Shurdington)",
  "41st Cheltenham",
  "Cranham Scouts",
  "1st Ruardean Scouts",
  "Enigma ESU Cheltenham",
  "Extreme ESU",
  "Devil's Chimney ESU",
  "Inferno Explorers",
  "WSJ Unit 81 - GlosJam",
  "Obsidian Explorer Scout Unit",
  "Team 162",
  "Forest of Dean Active Support",
  "Tewkesbury Network",
  "Meyer ESU",
  "Adders Tongue Explorers",
  "1st Moreton in Marsh Scouts",
  "1st Hatherley Scout Group",
  "3rd Tewkesbury Scout Group",
  "Gloucestershire Network",
  "Farriers ESU",
  "Gloucestershire County",
  "Roverway Glos",
  "1st Warden Hill, Cheltenham cubs",
  "1st Warden Hill, Cheltenham",
  "2nd twyning scouts",
  "1st Warden Hill",
  "1st Highbury",
  "1st Moreton in Marsh Guides",
  "1st Warden Hill, Cheltenham cubs",
  "1st. Warden Hill, Cheltenham",
  "3rd Tewkesbury cubs",
  "Cotswold Vale Explorers",
  "Deer Park (Kemble) Scouts",
  "1st Cirencester",
  "1st South Cerney",
  "1st Warden Hill Cheltenham",
  "Cheltenham Network",
  "Freeminers ESU",
  "No Name ESU",
  "White Stag Explorers",
];

export function isInGloucesterDistrict(group: string) {
  return gloucesterGroups.includes(group.trim());
}

export function isInGloucestershireCounty(group: string) {
  return gloucestershireGroups.includes(group.trim());
}
